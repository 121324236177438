@charset "UTF-8";
html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: 'Quicksand', sans-serif;
  // background: url(/images/login_bg.png) no-repeat center center fixed;
  background-color: #0fff77;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  .container.wrapper {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    .login-form {
      padding: 0px;
      border-radius: 25px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
    }
  }
}

//Color variables

$header-color: #1d3136;
$link-color: #0fff77;
$underline-color: #3d9db0;
$login-placeholder-color: #8492af;
$btn-color: #0fff77;
$white-color: #fff;
$grey-color-text: #3c5265;


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.no-padding {
  margin: 0px;
  padding: 0px;
}

header {
  background-color: $header-color;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}

//Styles for button

.btn.btn-primary {
  background-color: $btn-color;
  text-transform: initial;
  font-weight: bold;
  padding: 7px 90px;
  font-size: 1.2em;
  margin: 15px 0px 35px 0px;
  @include border-radius(30px);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: $header-color;
  &:hover, &:focus {
    background-color: $btn-color;
    outline: none;
  }
  &:active, &:active:focus {
    background-color: #2a606b;
    background-image: -webkit-radial-gradient(circle, #2a606b 10%, #346a6b 11%);
    background-image: -o-radial-gradient(circle, #2a606b 10%, #346a6b 11%);
    background-image: radial-gradient(circle, #2a606b 10%, #346a6b 11%);
    background-repeat: no-repeat;
    -webkit-background-size: 1000% 1000%;
    background-size: 1000% 1000%;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
}

.btn.btn-primary--arrow {
  margin: 15px 0px 20px 0px;
  &:after {
    content:" ";
    background-size:10px 10px;
    width: 15px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    left: 70px;
    position: relative;
  }
}
*, *:after, *:before  {
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding:0;
  margin:0;
}
.login-form {
  background-color: $white-color;
  .login-form__header {
    .logo-wrapper {
      padding: 10px 25px;
      display: inline-block;
      img {
        width: 35px;
        height: 60px;
      }
    }
    .nav.nav-tabs {
      display: inline-block;
      float: right;
      border-bottom: 0px;
      font-size: 18px;
      font-stretch: normal;
      line-height: 1.67;
      li a {
        padding: 25px;
        color: $white-color;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: 2.2;
        border-radius: 0px;
      }
      li.active a, li a:active, li a:focus, li a:hover, li.active a:focus, li.active a:active, li.active a:focus {
        color: $link-color;
        line-height: 2.2;
        background-color: transparent;
        outline: none;
      }
    }
  }
  .tab-content {
    h4 {
      margin: 0px;
      padding: 25px 0px;
      font-size: 25px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: $header-color;
    }
    form {
      text-align: center;
      .form-group {
        margin-bottom: 25px;
        border-radius: 20px;
        background-color: $white-color;
        border: solid 1.4px $grey-color-text;
        input:focus, input:active {
          -webkit-box-shadow: inset 0 -2px 0 $underline-color;
          box-shadow: inset 0 -2px 0 $underline-color;
        }
        .form-control.user {
          background: url(/images/ic-user.svg) no-repeat scroll 5px 7px;
          padding-left: 50px;
          border-radius: 36px;
        }
        .form-control.phone {
          background: url(/images/ic-phone.svg) no-repeat scroll 5px 7px;
          padding-left: 50px;
          border-radius: 36px;
        }
        .form-control.password {
          background: url(/images/ic-password.svg) no-repeat scroll 5px 7px;
          padding-left: 50px;
          border-radius: 36px;
        }
      }
      .forgot-pass-link {
        color: $grey-color-text;
        text-decoration: none;
        display: block;
        margin: auto;
        font-weight: 500;
        margin-bottom: 40px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.54;
      }
    }
  }
  .thank-for-register {
    text-align: center;
    p {
      font-size: 1.2emx;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #66848c;
    }
  }
}
#registration-form {
    margin-bottom: 5%;
}

p {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #66848c;
}

textarea, textarea.form-control, input.form-control, input[type=text], input[type=password], input[type=email], input[type=number], [type=text].form-control, [type=password].form-control, [type=email].form-control, [type=tel].form-control, [contenteditable].form-control {
  box-shadow: none;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #66848c;
  background: transparent;
  padding-left: 10px;
}

.login-form .tab-content form .form-group input:focus, .login-form .tab-content form .form-group input:active {
  box-shadow: none;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
  line-height: 1.43;
  color: #66848c;
}
.form-control::-webkit-input-placeholder { /* Firefox 19+ */
  font-size: 14px;
  line-height: 1.43;
  color: #66848c;
}
.form-control::-webkit-input-placeholder { /* IE 10+ */
  font-size: 14px;
  line-height: 1.43;
  color: #66848c;
}
.form-control::-webkit-input-placeholder { /* Firefox 18- */
  font-size: 14px;
  line-height: 1.43;
  color: #66848c;
}

p.dark-grey {
  color: $grey-color-text;
}

a {
    color: $grey-color-text;
    text-decoration: none;
}
a:hover, a:focus {
    color: $btn-color;
    text-decoration: underline;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input {outline: none;}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-text-fill-color: $grey-color-text !important; /* цвет текста */
    color: $grey-color-text !important; /* цвет текста */
}

span.select2.select2-container.select2-container--default {
    width: 100%!important;
}

.select2-container--default .select2-selection--single {
  border-radius: 20px!important;
  border: solid 1.4px #3c5265!important;
}

.select2-container .select2-selection--single {
  height: 36px!important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px !important;
  color: #66848c;
  text-align: start;
  padding-left: 50px;
}

span.select2-dropdown.select2-dropdown--below {
  border-radius: 20px!important;
  border: solid 1.4px #3c5265!important;
  margin-top: 8px;
}

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
    background: url(/images/ic-drop-up.svg) no-repeat center center;
    width: 24px;
    height: 24px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50%;
    width: 45px;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $white-color;
    color:#66848c;
  }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $white-color;
    color: #66848c;
  }
  .select2-container--default .select2-results>.select2-results__options {
    border-radius: 25px!important;
    color: #66848c;
    max-height: 350px!important;
    font-size: 14px!important;
  }

  .select2-dropdown .select2-results li {
    padding: 9px;
    background-color: #fff !important;
    border-top: 1px solid #3c5265;
  }

  .select2-dropdown .select2-results li:nth-child(1) {
    border-top: none;
  }
/*scrollBar */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e1e4e5;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $table-border-color;
}
@media screen and (max-width: 500px) {
  .login-form {
    .login-form__header {
      .logo-wrapper {
        vertical-align: middle;
        img {
          width: 70px;
          height: 30px;
        }
      }
      .nav.nav-tabs {
        li a {
          padding: 14px 25px;
        }
      }
    }
    .tab-content {
      h4 {
        font-size: 25px;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .login-form {
    .login-form__header {
      .nav.nav-tabs {
        li a {
          padding: 14px;
        }
      }
    }
  }
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 740px)
  and (orientation: landscape) {
  body {
    .container.wrapper {
      top: 0%;
      -webkit-transform: translateY(0%);
      -o-transform: translateY(0%);
      transform: translateY(0%);
    }
  }
}
